export const COGNITO_REDIRECT_TO = process.env.REACT_APP_COGNITO_REDIRECT_TO;
export const COGNITO_APP_WEB_DOMAIN = process.env.REACT_APP_COGNITO_APP_WEB_DOMAIN;
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
export const COGNITO_USERPOOL = process.env.REACT_APP_COGNITO_USERPOOL;
export const API_GATEWAY_API_KEY = process.env.REACT_APP_API_GATEWAY_API_KEY;
export const STAGE = process.env.REACT_APP_STAGE;
export const COGNITO_IDENTITYPOOL = process.env.REACT_APP_COGNITO_IDENTITYPOOL;
export const DEFAULT_MAP_NAME = process.env.REACT_APP_ALS_DEFAULT_MAP;
export const SATELLITE_MAP_NAME = process.env.REACT_APP_ALS_SATELLITE_MAP;
export const PLACE_INDEX = process.env.REACT_APP_ALS_PLACE_INDEX;
